.v-sidebar-desktop {
}

.v-sidebar-nav {
  height: calc(100% - 96px);
}

.v-hamburger-container {
  max-width: 100px;
}
.v-hamburger-container > div {
  height: 2px;
}

.icon-default-side .a,
.icon-default-side {
  fill: #6f7072;
  color: #6f7072;
}

.icon-active-side .a,
.icon-active-side {
  fill: white;
  color: white;
}

.icon-active-bottom .a,
.icon-active-bottom {
  fill: #6f7072;
  color: white;
}

@screen md {
  .v-header-inputs {
    grid-template-columns: minmax(0, 400px) auto;
  }
}

.v-header-top-nav {
  @apply container m-auto px-5 sm:px-0 items-center justify-between  w-full;
}

.my-table table th {
  padding: 16px 0;
  height: 56px;
}

.my-table table td {
  padding: 10.5px 0;
}
.my-table table {
  border-bottom: 2px solid #e4e5e7;
  height: 100%;
}
.my-table tbody {
  height: 100%;
  overflow-y: scroll;
}
/* .my-table thead, */
/* .my-table tbody {
  display: block;
  width: 100%;
} */

.tool-tip-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #00000029;
  border: 1px solid #a7a7a7;
  text-align: center;
  color: #000002;
  padding: 5px;
  position: relative;
}
.tool-tip-time {
  text-align: center;
  font: normal normal normal 10px/10px Noto Sans;
  letter-spacing: 0px;
  color: #000002;
  padding-bottom: 5px;
}
.tool-tip-value {
  text-align: center;
  font: normal normal 600 9px/10px Noto Sans;
  letter-spacing: 0px;
  color: #000002;
}
.tool-tip-container:after {
  position: absolute;
  content: '';
  z-index: 500000;
  width: 7px;
  height: 7px;
  background-color: red;
  transform: rotate(45deg);
  left: calc(50% - 3.5px);
  bottom: -3.5px;
  background-color: white;
  border-right: 1px solid #a7a7a7;
  border-bottom: 1px solid #a7a7a7;
}
#bar-chart-container {
  height: 100%;
  width: 100%;
}
.drop-down-box {
  border: 1px solid #707070;
}
.barchart-tooltip {
  padding: 12;
  color: #000002;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #00000029;
  border: 1px solid #a7a7a7;
  text-align: center;
  max-width: 200px;
  padding: 8px 14px;
  position: relative;
}
.barchart-tooltip-bottom {
  width: 30px;
  height: 30px;
  background-color: white;
  position: absolute;
  transform: rotate(45deg);
  left: calc(50% - 15px);
  top: calc(100% - 15px);
  border-bottom: 1px solid #a7a7a7;
  border-right: 1px solid #a7a7a7;
  z-index: 0;
}
