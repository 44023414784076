:root {
  --textSoft: #b2b2b2;
  --textGrey: #6f7072;
  --textBlack: #57585a;
  --blue: #0188b2;
  --black: #707070;
  --green: #74a343;
  --darkGreen: #019293;
  --orange: #f4a335;
  --red: #c83d5c;
  --grey: #d8d8d8;
  --bgGrey: #ebecee;
  --bgBlack: #414141;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans', sans-serif;
  /*outline: 1px dotted red;*/
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 8px;
}

.cloud {
  position: relative;
  fill: rgb(178, 178, 178);
  transform: scaleX(-1);
  left: -20px;
  top: 16px;
}

.svg-chlorine-active .a {
  fill: var(--blue);
}

.svg-turbidity-active .a {
  fill: #019293;
}

.svg-temperature-active .a {
  fill: var(--red);
}

.svg-ph-active .a {
  fill: var(--orange);
}

.text-normal-blue {
  text-align: left;
  font: normal normal 600 1.5rem/33px Noto Sans !important;
  letter-spacing: 0px;
  color: #0087b2;
  text-transform: uppercase;
  opacity: 1;
}

.text-thin-blue {
  text-align: left;
  font: normal normal 600 1.18rem/19px Noto Sans;
  font-weight: 500;
  letter-spacing: 0px;
  color: #0087b2;
  opacity: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-thin-black {
  text-align: left;
  font: normal normal 600 1.18rem/19px Noto Sans;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.drop-down {
  text-transform: capitalize;
}
.drop-down:active,
.drop-down:focus,
.drop-down:focus-visible {
  --tw-ring-opacity: 0;
  --tw-ring-offset-color: transparent;
}
.select-border-color {
  border: 1px solid #707070;
}
.select-border-radius {
  border-radius: 8px;
}
.black-drop-down .text-thin-blue {
  text-align: left;
  font: normal normal normal 16px/22px Noto Sans;
  letter-spacing: 0px;
  color: #6e6f71;
  opacity: 1;
}
.black-text .text-thin-blue {
  color: #000;
}
.no-table-border-bottom table {
  border-bottom: transparent;
}
.svg-chlorine-active {
  color: #0087b2;
}
.svg-ph-active {
  color: #d9683c;
}
.svg-temperature-active {
  color: var(--red);
}
.svg-turbidity-active {
  color: #019293;
}
.svg-conductivity-active {
  color: #009396;
  fill: #009396;
}

@media (max-width: 1536px) {
  html {
    font-size: 90%;
  }
}
@media (max-width: 1378px) {
  html {
    font-size: 80%;
  }
}
/* @media (max-width: 1378px) {
  html {
    font-size: 85%;
  }
} */
